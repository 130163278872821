<div class="container-fluid">
    <h3>Filters</h3>
    <div  class="filters col-lg-12 col-md-12 col-sm-12">
       
      <button id="addButton" class="btn btn-primary add-button" style="margin-left: 3%;" (click)="add()">Add +
        
      </button>
          
      </div>
      <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Employee Type</th>
        <th>Basic Pay</th>
        <th>PF Eligiability</th>
        <th>PF Employer</th>
        <th>PF Employee</th>
        <th>ESI Eligibility</th>
        <th>ESI Employer</th>
        <th>ESI Employee</th>
        <th>Status</th>
        <th>Action</th>
        </tr>
        </thead>
        <tbody >
            <tr
            *ngFor="
            let element of salaryConfigList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.employeeType}}</td>
              <td>{{element.basicPay}}</td>
              <td>{{element.pfEligibility}}</td>
              <td>{{element.pfEmployer}}</td>
              <td>{{element.pfEmployee }}</td>
              <td>{{element.esiEligibility}}</td>
              <td>{{element.esiEmployer}}</td>
              <td>{{element.esiEmployee}}</td>
              <td>
                <div class="example-button-container">
                  <mat-icon
            class="status-icon"
            style="width: 30%;"
            [style.color]="element.isActive ? 'green' : 'red'"
            (click)="toggleStatus(element)"
          >
            {{ element.isActive ? 'toggle_on' : 'toggle_off' }}
          </mat-icon>
                </div>
              </td>  
              <td>
              <td>
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
              </td>
            </tr>
            </tbody>
            </table>
     </div>
    </div>
