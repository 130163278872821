import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  labourData: string[] = ['Contract','Wages',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  salaryConfigForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  id:any;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.salaryConfigForm= this.formBuilder.group({
      employeeType: ['', Validators.required],
      basicPay: ['', Validators.required],
      pfEligibility: ['',Validators.required],
      pfEmployer: ['',Validators.required],
      pfEmployee: ['',Validators.required],
      esiEligibility: ['', Validators.required],
      esiEmployer: ['',Validators.required],
      esiEmployee: ['', Validators.required],
     
      
    });
 
  }

  goBack() {
    this.router.navigate(['/dashboard/salaryConfiguration']);
  }
 
  get f() {
    return this.salaryConfigForm.controls;
  }
  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.salaryConfigForm.patchValue({
      employeeType: data.employeeType,
      basicPay: data.basicPay,
      pfEligibility: data.pfEligibility,
      pfEmployer: data.pfEmployer,
      pfEmployee: data.pfEmployee ,
      esiEligibility:data.esiEligibility ,
      esiEmployer:data.esiEmployer ,
      esiEmployee: data.esiEmployee,
    })
  }


  onSubmit(data: any) {
    if (this.salaryConfigForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format()
   // data.isActive = true;

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/salaryConfiguration'])
    })
  }
  onCancel(){
    this.salaryConfigForm.reset()
  }
}

