
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Salary Configuration</h2>
    <form [formGroup]="salaryConfigForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="employeeType">Employee Type<span class="requried-field">*</span></label>
          <select
                    id="employeeType"
                    name="employeeType"
                    class="form-control"
                    formControlName="employeeType"
                    [ngClass]="{ 'is-invalid': submitted && f['employeeType'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of labourData"
                      [value]="data"
                      >{{ data}}
                    </option>
                  </select>
          <div *ngIf="submitted && f['employeeType'].errors" class="invalid-feedback">
            <div *ngIf="f['employeeType'].errors['required']">Employee Type is required</div>
          </div>
        </div>
      
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="basicPay">Basic Pay (%)<span class="requried-field">*</span></label>
            <input
              type="text"
              id="basicPay"
              placeholder="Enter basicPay"
              class="form-control"
              formControlName="basicPay"
              [ngClass]="{ 'is-invalid': submitted && f['basicPay'].errors }"
            />
            <div *ngIf="submitted && f['basicPay'].errors" class="invalid-feedback">
              <div *ngIf="f['basicPay'].errors['required']">Basic pay is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="pfEligibility">PF Eligibility<span class="requried-field">*</span></label>
            <input
              type="text"
              id="pfEligibility"
              placeholder="Enter pfEligibility"
              class="form-control"
              formControlName="pfEligibility"
              [ngClass]="{ 'is-invalid': submitted && f['pfEligibility'].errors }"
            />
            <div *ngIf="submitted && f['pfEligibility'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEligibility'].errors['required']">PF Eligibility is required</div>
            </div>
        </div>
       
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="pfEmployer">PF Employer (%)<span class="requried-field">*</span></label>
            <input
              type="text"
              id="pfEmployer"
              placeholder="Enter pfEmployer"
              class="form-control"
              formControlName="pfEmployer"
              [ngClass]="{ 'is-invalid': submitted && f['pfEmployer'].errors }"
            />
            <div *ngIf="submitted && f['pfEmployer'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEmployer'].errors['required']">PF Employer is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="pfEmployee">PF Employee (%)<span class="requried-field">*</span></label>
            <input
              type="text"
              id="pfEmployee"
              placeholder="Enter pfEmployee"
              class="form-control"
              formControlName="pfEmployee"
              [ngClass]="{ 'is-invalid': submitted && f['pfEmployee'].errors }"
            />
            <div *ngIf="submitted && f['pfEmployee'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEmployee'].errors['required']">PF Employee is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
          <label for="esiEligibility">ESI Eligibility<span class="requried-field">*</span></label>
          <input
            type="text"
            id="esiEligibility"
            placeholder="Enter esiEligibility"
            class="form-control"
            formControlName="esiEligibility"
            [ngClass]="{ 'is-invalid': submitted && f['esiEligibility'].errors }"
          />
          <div *ngIf="submitted && f['esiEligibility'].errors" class="invalid-feedback">
            <div *ngIf="f['esiEligibility'].errors['required']">ESI Eligibility is required</div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="esiEmployer">ESI Employer (%)<span class="requried-field">*</span></label>
            <input
              type="text"
              id="esiEmployer"
              placeholder="Enter esiEmployer"
              class="form-control"
              formControlName="esiEmployer"
              [ngClass]="{ 'is-invalid': submitted && f['esiEmployer'].errors }"
            />
            <div *ngIf="submitted && f['esiEmployer'].errors" class="invalid-feedback">
              <div *ngIf="f['esiEmployer'].errors['required']">ESI Employer is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="esiEmployee">ESI Employee (%) <span class="requried-field">*</span></label>
            <input
              type="text"
              id="esiEmployee"
              placeholder="Enter esiEmployee"
              class="form-control"
              formControlName="esiEmployee"
              [ngClass]="{ 'is-invalid': submitted && f['esiEmployee'].errors }"
            />
            <div *ngIf="submitted && f['esiEmployee'].errors" class="invalid-feedback">
              <div *ngIf="f['esiEmployee'].errors['required']">ESI Employee is required</div>
            </div>
        </div>
      </div>
  

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(salaryConfigForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  