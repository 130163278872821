import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AddComponent } from "./add/add.component";
import { EditComponent } from "./edit/edit.component";
import { SalaryConfigurationComponent } from "./salary-configuration.component";


const routes: Routes = [
  {
    path: "",
    component: SalaryConfigurationComponent
  },
  {
    path: "add",
    component: AddComponent
  },
  {
    path: "edit",
    component: EditComponent
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class salaryConfigRoutingModule {}
